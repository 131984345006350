@import '../../../libs/assets/fonts/icons/icons.css';
@import '@angular/cdk/overlay-prebuilt.css';

@supports not (-moz-appearance: none) {
    .chrome-scrollbar-padding {
        @apply lg:pr-[17px];
    }
}

:root {
    --font-headline: 'HyundaiSansHead', ui-sans-serif, system-ui, -apple-system, sans-serif;
    --font-text: 'HyundaiSansText', ui-sans-serif, system-ui, -apple-system, sans-serif;
}

html {
    @apply h-full scroll-smooth font-text text-16;
}

body {
    @apply h-full bg-grey-100;
}

@layer base {
    hr {
        @apply w-full border-grey-500;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p strong,
    b {
        @apply font-headline font-medium;
    }

    details summary::-webkit-details-marker {
        display: none;
    }
}

.custom-scrollbar-grey {
    scrollbar-color: #676767 #fff;
    scrollbar-width: thin;
}

.custom-scrollbar-grey::-webkit-scrollbar-track {
    @apply rounded-3 bg-white;
}

.custom-scrollbar-grey::-webkit-scrollbar {
    @apply w-6 bg-white;
}

.custom-scrollbar-grey::-webkit-scrollbar-thumb {
    @apply rounded-3 bg-grey-400;
}

@layer components {
    .button-primary {
        @apply relative inline-flex w-full items-center justify-center bg-accent px-20 py-16 text-center font-headline text-16 leading-20 text-white disabled:bg-grey-300 disabled:text-black;

        &:before {
            @apply bg-accent-cta-hover disabled:bg-transparent;
        }
    }

    .button-secondary {
        @apply relative inline-block w-full items-center border-2 border-accent px-20 py-16 text-center font-headline text-16 leading-20 text-accent;

        &:before {
            @apply bg-accent;
        }
    }

    .button-secondary.white {
        @apply border-white text-white;

        &:before {
            @apply bg-white;
        }

        &:after {
            @apply text-accent;
        }
    }

    .button-primary,
    .button-secondary {
        &:not(.button-not-animated) {
            @apply disabled:cursor-not-allowed;
            &:hover {
                &:before {
                    @apply right-0;
                }

                &:after {
                    @apply -translate-x-1/2 -translate-y-1/2 scale-100 opacity-100;
                }
            }

            &:before {
                @apply empty-content absolute bottom-0 left-0 right-full top-0 z-[1] block;
                transition: right 0.5s cubic-bezier(0.7, -0.02, 0, 1);
            }

            &:after {
                @apply icon-arrow-right-content absolute left-1/2 top-1/2 z-[1] block -translate-x-full -translate-y-1/2 scale-75 font-icon text-24 text-white opacity-0 disabled:empty-content;
                transition:
                    transform 0.1s ease-in 0.1s,
                    opacity 0.1s ease-out 0.1s;
            }
        }
    }

    .warn.button-primary {
        @apply bg-red;

        &:before {
            @apply bg-red-cta-hover;
        }
        &:after {
            @apply icon-fontawesome-warn-content disabled:empty-content;
        }
    }
    .save.button-primary:after {
        @apply icon-check-content disabled:empty-content;
    }

    .animated-arrow-right {
        @apply relative inline-block h-8 w-[28px];
        -webkit-transition: all 0.2s ease-in;
        transition: all 0.2s ease-in;

        &:before {
            @apply empty-content absolute right-0 block size-8 rotate-45;
            -webkit-transition: all 0.2s ease-in;
            transition: all 0.2s ease-in;
            border-style: solid;
            border-color: inherit;
            border-width: 2px 2px 0 0;
        }

        &:after {
            @apply empty-content absolute top-1/2 block h-0 w-[28px] -translate-y-1/2;
            -webkit-transition: all 0.2s ease-in;
            transition: all 0.2s ease-in;
            border-style: solid;
            border-color: inherit;
            border-width: 2px 0 0;
        }
    }

    .group:hover .animated-arrow-right,
    .group:hover .animated-arrow-right:after {
        width: 32px;
    }
}

@font-face {
    font-family: 'HyundaiSansHead';
    src:
        url('/assets/fonts/headline/hyundaisansheadkrmedium-webfont.woff2') format('woff2'),
        url('/assets/fonts/headline/hyundaisansheadkrmedium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'HyundaiSansHead';
    src:
        url('/assets/fonts/headline/hyundaisansheadkrregular-webfont.woff2') format('woff2'),
        url('/assets/fonts/headline/hyundaisansheadkrregular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HyundaiSansText';
    src:
        url('/assets/fonts/text/hyundaisanstextkrmedium-webfont.woff2') format('woff2'),
        url('/assets/fonts/text/hyundaisanstextkrmedium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HyundaiSansText';
    src:
        url('/assets/fonts/text/hyundaisanstextkrregular-webfont.woff2') format('woff2'),
        url('/assets/fonts/text/hyundaisanstextkrregular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

.tooltip:hover .tooltip-content {
    @apply visible opacity-100;
}
