@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../../global.styles.scss';

.footnote {
    @apply cursor-pointer;
}

/* CUSTOM Webkit
 SCROLLBAR FOR HORIZONTALE USAGE */

body:has(div.hy-osr-iframe-wrapper) {
    @apply overflow-hidden lg:pr-[17px];
}

.hy-osr-iframe-wrapper {
    @media (max-width: 768px) {
        background-color: #002b5f;
        padding-top: 50px;

        .hy-osr-overlay {
            background: transparent !important;
        }
        .hy-osr-iframe-container {
            height: calc(100dvh - 50px) !important;
        }
    }
}
